:root {
    --progress-height: 50px;
    --progress-width: calc(100vw - 100px);
}

html, body {
    margin: 0;
    padding: 0;
    font-size: 0; /* A non-zero font size can cause unwanted spacing between elements */
    height: 100vh;
    overflow: hidden;
    position: fixed;
    background-color: black;
    font-family: sans-serif;
}

canvas {
    height: calc(100 * var(--vh) - var(--progress-height));
    width: 100vw;
}

div {
    font-size: 16px;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: var(--progress-width); /* Full-width */
    height: var(--progress-height); /* Specified height */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .1s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 0;
    background-size: 100% 100%;
    line-height: var(--progress-height);
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */ 
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 2px; /* Set a specific slider handle width */
    height: var(--progress-height); /* Slider handle height */
    background: black; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 2px; /* Set a specific slider handle width */
    height: var(--progress-height); /* Slider handle height */
    background: black; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

canvas, input {
    margin: 0;
    padding: 0;
}

input {
    float: left;
}

#footer {
    width: 100vw;
    height: var(--progress-height);
}

#timestamp {
    left: var(--progress-width);
    height: var(--progress-height);
    width: calc(100vw - var(--progress-width));
    background-color: #111111;
    color: white;
    float: left;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}